@font-face {
  font-family: Bungee;
  src: url(./assets/fonts/Bungee-Regular.ttf) format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111315 !important;
  color: white !important;
  width: 100%;
  height: 100%;

  overflow: hidden;
}
body::-webkit-scrollbar {
  display: none;
}

.text-animation {
  font-size: 36px;
  font-family: Arial, sans-serif;
  display: inline-block;
}

.text-animation {
  display: inline-block;
  transform-origin: center bottom;
  animation: bounce 4s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  100% {
    transform: translateY(-4px) scale(1.1);
  }
}

#box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 200px;
  color: white;
  font-family: "Raleway";
  font-size: 2.5rem;
}
